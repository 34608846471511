@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply font-eh-primary relative overflow-x-hidden scroll-smooth;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  body.no-scroll {
    @apply h-screen overflow-hidden;
  }

  p,
  span,
  a {
    @apply text-sm sm:text-base;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  .smooth {
    @apply transition-all duration-200;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }
}

@layer components {
  .btn {
    @apply btn--base btn--pseudo children-path:fill-white bg-eh-flame-pea border-2 border-eh-flame-pea text-white [&:not([disabled])]:hover:border-eh-flame-pea-600 [&:not([disabled])]:hover:bg-eh-flame-pea-600 [&:not([disabled])]:focus:bg-eh-flame-pea-600;
  }

  .btn.primary-outline {
    @apply btn--base btn--pseudo children-path:fill-eh-flame-pea bg-transparent text-eh-flame-pea border-2 border-eh-flame-pea [&:not([disabled])]:children-path:hover:fill-white [&:not([disabled])]:hover:text-white [&:not([disabled])]:hover:bg-eh-flame-pea-600 [&:not([disabled])]:focus:bg-eh-flame-pea-400 [&:not([disabled])]:focus:text-white;
  }

  .btn.primary-bare {
    @apply -ml-8 !font-light btn--base btn--pseudo bg-transparent border-transparent children-path:fill-eh-flame-pea text-eh-flame-pea [&:not([disabled])]:hover:bg-eh-flame-pea-600 [&:not([disabled])]:hover:border-eh-flame-pea-600 [&:not([disabled])]:hover:text-white [&:not([disabled])]:focus:text-eh-burnt-sienna [&:not([disabled])]:children-path:hover:fill-white [&:not([disabled])]:children-path:focus:fill-eh-burnt-sienna;
  }

  .btn.primary-link {
    @apply !px-0 !font-light btn--base btn--pseudo bg-transparent border-transparent children-path:fill-eh-flame-pea text-eh-flame-pea [&:not([disabled])]:hover:bg-transparent [&:not([disabled])]:hover:border-transparent [&:not([disabled])]:hover:underline [&:not([disabled])]:focus:bg-transparent [&:not([disabled])]:hover:text-eh-burnt-sienna [&:not([disabled])]:focus:text-eh-burnt-sienna [&:not([disabled])]:children-path:hover:fill-eh-burnt-sienna [&:not([disabled])]:children-path:focus:fill-eh-burnt-sienna;
  }

  .btn.secondary {
    @apply btn--base btn--pseudo [&:not([disabled])]:hover:border-eh-burnt-sienna-600 bg-eh-burnt-sienna text-white border-2 border-eh-burnt-sienna [&:not([disabled])]:hover:bg-eh-burnt-sienna-600 [&:not([disabled])]:focus:bg-eh-burnt-sienna-600;
  }

  .btn.white-outline {
    @apply btn--base btn--pseudo bg-transparent text-white border-2 border-white [&:not([disabled])]:hover:bg-eh-flame-pea-600 [&:not([disabled])]:focus:bg-eh-flame-pea-600 [&:not([disabled])]:focus:text-white;
  }

  .btn.transparent {
    @apply btn--base btn--pseudo !bg-transparent !border-transparent children-path:fill-eh-flame-pea text-black  [&:not([disabled])]:[&>svg]:hover:translate-x-1 [&:not([disabled])]:hover:underline [&>svg]:transition-transform !px-0;
  }

  .section {
    @apply py-10 lg:py-20;
  }

  .content {
    @apply content--base content--pseudo content--paragraphs content--headings content--images;
  }

  .content-sm {
    @apply content--base content--pseudo content--paragraphs content--headings-sm;
  }

  .content ul {
    @apply list-disc pl-5 flex flex-col gap-y-2;
  }
  .content ol {
    @apply list-decimal pl-5 flex flex-col gap-y-2;
  }

  .content p > a > img {
    margin: 0 0.5rem 2px 0 !important;
    display: inline-block;
  }
}

@layer utilities {
  .btn--pseudo {
    @apply transition-colors children-path:transition-colors disabled:opacity-75;
  }

  .btn--base {
    @apply py-2 px-8 items-center gap-3 font-bold rounded-full inline-flex;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .content--pseudo {
    @apply children-a:underline hover:children-a:text-eh-flame-pea;
  }

  .content--base {
    @apply last:[&>*]:mb-0 children-b:font-bold children-strong:font-bold children-img:rounded-[5px] lg:children-img:rounded-[10px] md:children-blockquote:px-20 children-p:children-blockquote:text-2xl lg:children-p:children-blockquote:text-4xl children-blockquote:italic children-li:font-light;
  }

  .content--images {
    @apply [&_img]:!w-full md:[&_img]:!w-auto [&_img]:!mx-auto [&_img]:!my-12 lg:[&_img]:!my-20 [&_iframe]:rounded-xl [&_iframe]:overflow-hidden;
  }

  .content--paragraphs {
    @apply children-p:leading-[1.75] children-p:mb-2 md:children-p:mb-6 children-p:font-light children-li:leading-[1.75];
  }

  .content--headings {
    @apply children-headings:mb-2 sm:children-headings:mb-6 children-h1:mb-5 children-h1:text-2xl xs:children-h1:text-3xl lg:children-h1:text-6xl children-h2:text-xl xs:children-h2:text-2xl lg:children-h2:text-3xl children-h3:text-2xl;
  }

  .content--headings-sm {
    @apply children-headings:mb-6 children-h1:mb-5 children-h1:text-2xl xs:children-h1:text-3xl lg:children-h1:text-5xl children-h2:text-2xl lg:children-h2:text-4xl children-h3:text-2xl;
  }
}

/* WordPress helpers */

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}

/* Progress bar */

#nprogress .bar {
  @apply bg-eh-flame-pea;
}

#nprogress .spinner-icon {
  @apply hidden;
}

#nprogress .peg {
  @apply shadow;
}

/* Form helpers */

.form-duuf-submit > button {
  @apply btn;
}

.form-duuf-loading {
  @apply absolute inset-0 z-10 bg-transparent flex-center;
}

.form-duuf-label {
  @apply block font-bold pb-1 sm:pb-3;
}

.form-duuf-input,
.form-duuf-textarea,
.form-duuf-select__control {
  @apply rounded-[66px] w-full px-7 border h-12 border-black/50 placeholder:text-black/40;
}

.form-duuf-group-name {
  @apply w-full grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-5;
}

.form-duuf-textarea {
  @apply pt-5 h-[200px] rounded-[20px];
}

.form-duuf-group-consent > div {
  @apply gap-5 font-light text-sm;
}

.form-duuf-group-consent > div a {
  @apply text-eh-flame-pea underline;
}

.form-duuf-file input {
  @apply hidden;
}

.form-duuf-select__control {
  @apply !rounded-[66px] !border-black/50;
}

.form-duuf-select__placeholder {
  @apply !text-black/40;
}

.form-duuf-select__indicator-separator {
  @apply hidden;
}

.form-duuf-select__indicator {
  @apply !px-0;
}

.form-duuf-select__value-container {
  @apply !p-0;
}

.form-duuf-file-button button {
  @apply btn secondary py-1 px-2;
}

.form-duuf-checkbox {
  @apply min-w-[1rem] min-h-[1rem] max-w-[1rem] max-h-[1rem];
}

.form-modal-wrapper > form {
  @apply grid grid-cols-2 gap-3 lg:gap-5;
}

.form-modal-wrapper > form .form-duuf-group {
  @apply relative;
}

.form-modal-wrapper > form .form-duuf-group {
  @apply col-span-2 md:col-span-1;
}

.form-modal-wrapper > form .form-duuf-group-name {
  @apply col-span-2;
}

.form-modal-wrapper > form .form-duuf-group-textarea {
  @apply col-span-2;
}

.form-modal-wrapper > form .form-duuf-error {
  @apply absolute right-0 top-0 text-sm;
}

.losse-kruumel-history {
  @apply flex items-center gap-2 cursor-pointer;
}

/* Custom */

.swiper-slide {
  @apply !overflow-visible;
}

.three-visible .swiper-slide {
  @apply opacity-0 duration-200 transition-all;
}

.three-visible .swiper-slide-active,
.three-visible .swiper-slide-next,
.three-visible .swiper-slide-prev {
  @apply opacity-100;
}

.two-visible .swiper-slide {
  @apply opacity-0 duration-200 transition-all;
}

.two-visible .swiper-slide-active,
.two-visible .swiper-slide-next {
  @apply opacity-100;
}

.swiper-button-disabled {
  @apply opacity-40;
}

.swiper-3d .swiper-wrapper {
  transform-style: initial !important;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  text-rendering: auto;
}

.widget-123boeken-results .widget-123boeken-item--custom {
  @apply bg-white rounded-2xl !border-none overflow-hidden shadow-eh-primary !mb-8;
}

.widget-123boeken-item--custom .widget-123boeken-foot {
  @apply relative z-10;
}

.widget-123boeken-item--custom .widget-123boeken-btn {
  @apply !border-none !shadow-none !rounded-full !bg-eh-flame-pea !font-bold;
}

.widget-123boeken-item--custom .widget-123boeken-description:after {
  content: none !important;
}

.widget-123boeken-item--custom .widget-123boeken-description {
  @apply !m-0;
}

.widget-123boeken-item--custom .widget-123boeken-price > div > span {
  @apply !bg-eh-cherokee !rounded-full !font-bold;
}

#onze-mogelijkheden .swiper .swiper-slide-active {
  @apply -top-3;
}

#onze-mogelijkheden .swiper .swiper-slide .swiper-slide-image{
  @apply object-cover w-full rounded-lg max-h-full !h-[200px] lg:!h-[320px];
}

#onze-mogelijkheden .swiper .swiper-slide-active .swiper-slide-image {
  @apply !h-[250px] lg:!h-[320px];
}
